'use client';
import {
  AlphaSlider as MantineAlphaSlider,
  Box as MantineBox,
  ColorSwatch as MantineColorSwatch,
  createVarsResolver,
  factory,
  getSize,
  getSpacing,
  HueSlider as MantineHueSlider,
  useProps,
  useStyles
} from '@mantine/core';
import { useDidUpdate } from '@mantine/hooks';
import { useRef, useState } from 'react';

import { useUncontrolled } from '../../../hooks/useUncontrolled';
import { useTheme } from '../../../theme/useTheme';
import { Divider } from '../../Divider';
import { Stack } from '../../Stack';
import { Text } from '../../Text';
import { ColorPickerProvider } from './ColorPickerElement.context';
import classes from './ColorPickerElement.module.css';
import {
  ColorPickerElementFactory,
  ColorPickerElementProps,
  HsvaColor
} from './ColorPickerElement.types';
import { convertHsvaTo, isColorValid, parseColor } from './converters';
import { EyeDropperButton } from './EyeDropper';
import { ColorPickerElementInput as Input } from './Input';
import { Saturation } from './Saturation';
import { Swatches } from './Swatches/Swatches';

const defaultProps: Partial<ColorPickerElementProps> = {
  swatchesPerRow: 10,
  withPicker: true,
  focusable: true,
  withEyeDropper: true,
  format: 'hexa',
  size: 'sm',
  emptyColor: '#FFFFFF',
  __staticSelector: 'ColorPickerElement'
};

const varsResolver = createVarsResolver<ColorPickerElementFactory>(
  (_, { size, swatchesPerRow }) => ({
    wrapper: {
      '--cp-preview-size': getSize(size, 'cp-preview-size'),
      '--cp-width': getSize(size, 'cp-width'),
      '--cp-body-spacing': getSpacing(size),
      '--cp-swatch-size': `${100 / swatchesPerRow!}%`,
      '--cp-thumb-size': getSize(size, 'cp-thumb-size'),
      '--cp-saturation-height': getSize(size, 'cp-saturation-height'),
      '--cp-input-wrapper-gap': getSpacing(size)
    }
  })
);

export const ColorPickerElement = factory<ColorPickerElementFactory>(
  (_props, ref) => {
    const props = useProps('ColorPickerElement', defaultProps, _props);
    const theme = useTheme();
    const {
      classNames,
      className,
      style,
      styles,
      unstyled,
      vars,
      format,
      value: valueProp,
      defaultValue,
      onChange,
      onChangeEnd,
      withPicker,
      size,
      saturationLabel,
      hueLabel,
      alphaLabel,
      focusable,
      palettes: palettesProp,
      swatchesPerRow,
      fullWidth,
      onColorSwatchClick,
      __staticSelector,
      withEyeDropper,
      emptyColor,
      clearEnabled,
      ...others
    } = props;

    const getStyles = useStyles<ColorPickerElementFactory>({
      name: __staticSelector!,
      props,
      classes,
      className,
      style,
      classNames,
      styles,
      unstyled,
      rootSelector: 'wrapper',
      vars,
      varsResolver
    });

    const formatRef = useRef(format);
    const valueRef = useRef<string>();
    const updateRef = useRef(true);
    const scrubTimeoutRef = useRef<number>(-1);
    const isScrubbingRef = useRef(false);
    const withAlpha =
      format === 'hexa' || format === 'rgba' || format === 'hsla';
    const palettes = palettesProp || theme.other.colors.palettes || [];

    const [value, setValue, controlled] = useUncontrolled({
      value: valueProp,
      defaultValue,
      finalValue: null,
      onChange
    });

    const [parsed, setParsed] = useState<HsvaColor>(
      parseColor(value || emptyColor)
    );
    const startScrubbing = () => {
      window.clearTimeout(scrubTimeoutRef.current);
      isScrubbingRef.current = true;
    };

    const stopScrubbing = () => {
      window.clearTimeout(scrubTimeoutRef.current);
      scrubTimeoutRef.current = window.setTimeout(() => {
        isScrubbingRef.current = false;
      }, 200);
    };

    const handleChange = (color: Partial<HsvaColor>) => {
      updateRef.current = false;
      setParsed((current) => {
        const next = { ...current, ...color };
        valueRef.current = convertHsvaTo(formatRef.current!, next);
        return next;
      });

      setValue(valueRef.current!);

      // Does not work any other way
      setTimeout(() => {
        updateRef.current = true;
      }, 0);
    };

    useDidUpdate(() => {
      const colorValue = isColorValid(value!) ? value : emptyColor;
      if (isColorValid(colorValue!) && !isScrubbingRef.current) {
        setParsed(parseColor(colorValue!));
      }
    }, [value]);

    useDidUpdate(() => {
      formatRef.current = format;
      setValue(convertHsvaTo(format!, parsed));
    }, [format]);

    const hasPalettes = (palettes || []).some((p) => p.hasItems());

    return (
      <ColorPickerProvider value={{ getStyles, unstyled }}>
        <MantineBox
          ref={ref}
          {...getStyles('wrapper')}
          size={size}
          mod={{ 'full-width': fullWidth }}
          {...others}
        >
          {withPicker && (
            <>
              <Saturation
                value={parsed}
                onChange={handleChange}
                onChangeEnd={({ s, v }) =>
                  onChangeEnd?.(
                    convertHsvaTo(formatRef.current!, {
                      ...parsed,
                      s: s!,
                      v: v!
                    })
                  )
                }
                color={value || emptyColor}
                size={size!}
                focusable={focusable}
                saturationLabel={saturationLabel}
                onScrubStart={startScrubbing}
                onScrubEnd={stopScrubbing}
              />

              <div {...getStyles('body')}>
                <div {...getStyles('slidersWrapper')}>
                  <div {...getStyles('sliders')}>
                    <MantineHueSlider
                      value={parsed.h}
                      onChange={(h) => handleChange({ h })}
                      onChangeEnd={(h) =>
                        onChangeEnd?.(
                          convertHsvaTo(formatRef.current!, { ...parsed, h })
                        )
                      }
                      size={size}
                      focusable={focusable}
                      aria-label={hueLabel}
                      onScrubStart={startScrubbing}
                      onScrubEnd={stopScrubbing}
                    />

                    {withAlpha && (
                      <MantineAlphaSlider
                        value={parsed.a}
                        onChange={(a) => handleChange({ a })}
                        onChangeEnd={(a) => {
                          onChangeEnd?.(
                            convertHsvaTo(formatRef.current!, { ...parsed, a })
                          );
                        }}
                        size={size}
                        color={convertHsvaTo('hex', parsed)}
                        focusable={focusable}
                        aria-label={alphaLabel}
                        onScrubStart={startScrubbing}
                        onScrubEnd={stopScrubbing}
                      />
                    )}
                  </div>

                  {withAlpha && (
                    <MantineColorSwatch
                      color={value || emptyColor}
                      radius="sm"
                      size="var(--cp-preview-size)"
                      {...getStyles('preview')}
                    />
                  )}
                </div>

                <div {...getStyles('inputWrapper')}>
                  <Input
                    format={format}
                    value={value}
                    clearEnabled={clearEnabled}
                    emptyColor={emptyColor}
                    onClear={() => {
                      if (clearEnabled) {
                        if (emptyColor) {
                          setParsed(parseColor(emptyColor!));
                        }
                        onChangeEnd?.('');
                      }
                    }}
                    onChangeEnd={(c) => {
                      handleChange(c);
                      onChangeEnd?.(convertHsvaTo(formatRef.current, c));
                    }}
                  />
                  {withEyeDropper && (
                    <EyeDropperButton
                      onChange={(c) => {
                        handleChange(c);
                        onChangeEnd?.(convertHsvaTo(formatRef.current, c));
                      }}
                    />
                  )}
                </div>
              </div>
            </>
          )}

          {hasPalettes && (
            <Stack mt="0.5rem" gap="0.25rem">
              {palettes
                .filter((p) => p.hasItems())
                .map((p) => (
                  <Stack gap="0.25rem" key={p.id.toString()}>
                    <Text size="xs">{p.label}</Text>
                    <Divider />
                    <Swatches
                      data={p.items}
                      swatchesPerRow={swatchesPerRow}
                      focusable={focusable}
                      setValue={setValue}
                      onChangeEnd={(color) => {
                        const convertedColor = convertHsvaTo(
                          format!,
                          parseColor(color)
                        );
                        onColorSwatchClick?.(convertedColor);
                        onChangeEnd?.(convertedColor);
                        if (!controlled) {
                          setParsed(parseColor(color));
                        }
                      }}
                    />
                  </Stack>
                ))}
            </Stack>
          )}
        </MantineBox>
      </ColorPickerProvider>
    );
  }
);

ColorPickerElement.classes = classes;
ColorPickerElement.displayName = 'ColorPickerElement';
